/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import favicon from '../../images/favicon.svg';
 import faviconICO from '../../images/favicons/favicon.ico';
 import favicon32 from '../../images/favicons/favicon-32x32.png';
 import favicon16 from '../../images/favicons/favicon-16x16.png';
 import appleTouch from '../../images/favicons/apple-touch-icon.png';
 import { seoData } from './data';
  
 const Seo = ({ meta, defaultTitle }) => {
   const { title, description, ogTitle, ogImage, ogDescription } = seoData;

   return (
     <Helmet
       title={defaultTitle ?? title}
       meta={[
         {
           name: `description`,
           content: description,
         },
         {
           property: `title`,
           content: title,
         },
         {
          property: `og:title`,
          content: ogTitle,
        },
         {
           property: `og:description`,
           content: ogDescription,
         },
         {
           property: `og:type`,
           content: `website`,
         },
         {
          property: `og:url`,
          content: `impact-strat.com`,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        //  {
        //    name: `twitter:card`,
        //    content: `summary`,
        //  },
        //  {
        //    name: `twitter:creator`,
        //    content: site.siteMetadata?.social?.twitter || ``,
        //  },
        //  {
        //    name: `twitter:title`,
        //    content: title,
        //  },
        //  {
        //    name: `twitter:description`,
        //    content: metaDescription,
        //  },
       ].concat(meta)}>
        <link rel="preconnect" href="https://fonts.googleapis.com"/> 
        <link href="https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@500;800&display=swap" rel="stylesheet"></link>
        <link rel="stylesheet" href="https://use.typekit.net/aaz3qqd.css"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"/>

        
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        {/* <link rel="manifest" href="/site.webmanifest"/> */}
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ac441e"/>
        <meta name="msapplication-TileColor" content="#ac441e"/>
        <meta name="theme-color" content="#ffffff"/>
      </Helmet>
   )
 }
 Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: `We help global businesses find new growth.`,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  defaultTitle: PropTypes.string,
}
 export default Seo;