import styled from "styled-components";
// import PropTypes from "prop-types"
import logo from '../../images/impact-strategies-logo.svg';
import { TeamCard } from "modules";
import JoeyImage from '../../images/impact-photo-Joey2.jpg';
import SophieImage from '../../images/impact-photo-sophie.jpg';
import EmilyImage from '../../images/impact-photo-emily.jpg';
import NatalieImage from '../../images/impact-photo-natalie.jpg';
import { screenSize } from 'theme';
import Swiper from 'react-id-swiper';
import { withManager } from 'utilities';

import 'swiper/css/swiper.css';

 const SliderWrapper = styled.div`
  min-height: 600px;
  overflow: hidden;
  max-width: 100%;
  margin-top: -180px;
  padding: 64px 0;

  & .swiper-wrapper {
    padding-left: 17%;
  }

  & .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    width: auto;
  }

	@media only screen and (max-width: ${screenSize.mobile}) {
    min-height: 400px;
  
  }
`;

 const Slider = (props) => {
   const { viewport, index } = props;

   const params = {
    slidesPerView: 'auto',
    mousewheel: false,
    spaceBetween: 120,
    grabCursor: true,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    observer: true,
    breakpoints: {
      1580: {
        spaceBetween: 140
      },
      1280: {
        spaceBetween: 120
      },
      960: {
        spaceBetween: 80
      },
      768: {
        spaceBetween: 60
      },
      576: {
        spaceBetween: 80
      },
      480: {
        spaceBetween: 50
      }
    }
  }

   return (
     <SliderWrapper id="sliderComponent" viewport={viewport}>
      <Swiper {...params}>
        <div>
          <TeamCard 
            name="Joey Kelner"
            title="Managing Director"
            location="Chicago, IL"
            image={JoeyImage}/>
        </div>
        <div>
          <TeamCard 
            name="Natalie George"
            title="Insights & Strategy Director"
            location="Denver, CO"
            image={NatalieImage}/>
        </div>
        <div>
          <TeamCard 
            name="Emily Polander"
            title="Senior Insights & Strategy Consultant"
            location="Columbus, OH"
            image={EmilyImage}/>
        </div>
        <div>
          <TeamCard 
            lastItem
            name="Sophia Grace"
            title="Insights & Strategy Consultant"
            location="Denver, CO"
            image={SophieImage}/>
        </div>
      </Swiper>
    </SliderWrapper>
   );
 }

export default withManager(Slider);