import styled from "styled-components";
import { Link } from 'gatsby';
// import PropTypes from "prop-types"
import { Text, Body } from 'components';
import { colors, screenSize } from 'theme';
import ScrollAnimation from 'react-animate-on-scroll';
import { withManager } from 'utilities';
import lightLogo from '../../images/impact-strategies-logo-light.png';
// import AreReady from '../../images/svgs/impact-ready.svg';
import AreReadyHighlight from '../../images/svgs/impact-ready-highlight.svg';
import AreReadyShadow from '../../images/svgs/impact-ready-shadow.svg';
import AreReadyFill from '../../images/svgs/impact-ready-fill.svg';
import AreReadyHighlightMobile from '../../images/svgs/impact-ready-highlight-mobile.svg';
import AreReadyShadowMobile from '../../images/svgs/impact-ready-shadow-mobile.svg';
import AreReadyFillMobile from '../../images/svgs/impact-ready-fill-mobile.svg';
import DotPatternCream from '../../images/svgs/dot-pattern-cream.svg';

 const FooterWrapper = styled.footer`
  height: 80%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #f2edd9;
  padding: 0 20px 26px 20px;
  box-sizing: border-box;
  position: relative;
  align-items: center;

  & .footerParallax {
    width: 100%;
  }

  ::before {
    content: ' ';
    position: absolute;
    top: 0;
    z-index: 1;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 105px;
    background: url(${DotPatternCream}) center;
  }
  

  ::after {
    content: ' ';
    
  }

  @media only screen and (max-width: ${screenSize.tablet}) {
    margin: 0;
    padding: 0;
  }
`;

const TopBar = styled.div`
  position: absolute;
    top: 0;
    z-index: 2;
    width: 85%;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 20px;
    background: ${colors.orange};
`;

const FooterBottom = styled.div`
  background-color: ${colors.orange};
  display: flex;
  justify-content: space-between;
  width: 85%;
  max-width: 1600px;
  padding: 64px 32px;
  box-shadow: 0 3px 16px rgba(0,0,0,0.4);
  align-self: flex-end;
  position: relative;
  bottom: -26px;
  margin: 0 auto;

  @media only screen and (max-width: ${screenSize.tablet}) {
    padding: 32px;
    flex-direction: column;
    width: 100%;
  }
`;

const FooterLeftWrapper = styled.div`
  padding-left: 40px;
  padding-bottom: 26px;
  width: 50%;
  
  @media only screen and (max-width: ${screenSize.tablet}) {
    padding-left: 0;
    width: 100%;
  }
`;

const FooterRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
  align-items: flex-end;

  @media only screen and (max-width: ${screenSize.tablet}) {
    width: 100%;
    align-items: center;
  }
`;

const FooterCopyright = styled.div`
  list-style-type: none;
  font-family: "forma-djr-text", Helvetica, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: ${colors.darkOrange};

  a {
    text-decoration: none ;
    color: ${colors.darkOrange};
    transition: all .3s ease ;
  }
  a:hover { 
    text-decoration: underline ;
  }

  @media only screen and (max-width: ${screenSize.tablet}) {
    text-align: center;
    font-size: 12px;
    margin-top: 0;
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: 16px;
`;

const LogoImage = styled.img`
  width: 250px;

  @media only screen and (max-width: 580px) {
  }
`;

const ReadyImage = styled.img`
  max-width: 90%;
  margin: 0 auto;
  position: ${({absolute}) => absolute ? 'absolute' : 'relative'};
  /* z-index: 2; */

  @media only screen and (max-width: ${screenSize.tablet}) {
    margin-top: 32px;
  }
  @media only screen and (max-width: ${screenSize.tablet}) {
    max-width: 100%;
  
  }
`;

const ReadyWrapper = styled.div`
    width: 100%;
    display: flex;
    height: 700px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 3;

    & .readyImage {
      width: 100%;
      height: 100%;
      position: absolute;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 1720px;
    }
    @media only screen and (max-width: ${screenSize.tablet}) {
      height: 400px;
    }
    @media only screen and (max-width: ${screenSize.tablet}) {
      height: 300px;
    }
`;
  
const Footer = (props) => {
  const { viewport } = props;

  return (
    <FooterWrapper viewport={viewport} id="contact">
      <TopBar/>
      <ReadyWrapper>
      <ScrollAnimation className="readyImage" animateOnce animateIn="readyHighlight" offset={100} delay={800}>
        <ReadyImage absolute src={viewport === 'desktop' ? AreReadyHighlight : AreReadyHighlightMobile}/>
      </ScrollAnimation>
      <div className="readyImage">
        <ReadyImage absolute src={viewport === 'desktop' ? AreReadyFill : AreReadyFillMobile}/>
      </div>
      <ScrollAnimation className="readyImage" animateOnce animateIn="readyShadow" offset={100} delay={800}>
        <ReadyImage absolute src={viewport === 'desktop' ? AreReadyShadow : AreReadyShadowMobile}/>
      </ScrollAnimation>
      </ReadyWrapper>
        <FooterBottom>
          <FooterLeftWrapper>
              <Text size="small" fontWeight="bold" color={colors.beige}>INQUIRIES</Text>
              <Spacer/>
              <Body size="medium" fontWeight="bold" color={colors.white}>
                <span style={{ color: colors.beige}}>P:</span> 317.332.8312
              </Body>
              <Body size="medium" fontWeight="bold" color={colors.white}>
                <span style={{ color: colors.beige}}>E:</span> contact@impact-strat.com
              </Body>
          </FooterLeftWrapper>
          <FooterRightWrapper>
              <LogoImage src={lightLogo} alt="impact strategies consulting" title="impact strategies consulting"/>
            <FooterCopyright>© 2021 Impact Strategic Consulting, All Rights Reserved</FooterCopyright>
            <div style={{ height: '16px'}}></div>
            <FooterCopyright><Link to="/privacy-policy">Privacy Policy</Link></FooterCopyright>
          </FooterRightWrapper>
        </FooterBottom>
    </FooterWrapper>
   );
 }

export default withManager(Footer);