import styled from "styled-components";
import PropTypes from "prop-types";
import { Text, Body } from "components";
import { colors, screenSize } from "theme";
import Number1 from '../../images/svgs/impact-intro-number_01.svg';
import Number2 from '../../images/svgs/impact-intro-number_02.svg';
import Number3 from '../../images/svgs/impact-intro-number_03.svg';
import { withManager } from 'utilities';

const propTypes = {
  imageSource: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  category: PropTypes.string,
  hideButton: PropTypes.bool,
  index: PropTypes.number
};

const defaultProps = {
  index: 0
};

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 550px;
  background-size: cover;
  box-sizing: border-box;
  position: relative;
  margin:  -64px 0 0 0 ;

  @media only screen and (max-width: ${screenSize.tablet}) {
    margin: 12px 0;
    width:  100%;
  }

`;

const TopContentContainer = styled.div`
  padding: 32px;
  background: ${colors.white};
  min-height: 300px;
  margin-bottom: 4px;
  box-shadow: 0 9px 26px rgba(0, 0, 0, 0.2);

  @media only screen and (min-width: 1600px) {
    min-height: 0;

  }

  @media only screen and (max-width: 1300px) {
    min-height: 350px;
  }
  @media only screen and (max-width: 1100px) {
    min-height: 400px;
  }
  @media only screen and (max-width: 900px) {
    min-height: 500px;
  }
  @media only screen and (max-width: ${screenSize.tablet}) {
    min-height: 0;
    height: auto;
  }

`;

const BottomContentContainer = styled.div`
  padding: 32px 32px 16px 32px ;
  background: ${colors.white}b0;
  min-height: 225px;

  @media only screen and (max-width: 1200px) {
    min-height: 300px;
  }

  @media only screen and (max-width: ${screenSize.tablet}) {
    min-height: 0;
  }
`;

const TopContainer = styled.div`
  height: 50px;
  width: 100%;
  background-color: ${colors.olive}CC;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 4px solid ${colors.darkOlive};
`;

const DeliverablesList = styled.ul`
  padding-left: 21px;
`;

const ImageNumber = styled.img`
  height: 28px;
`;

const Spacer = styled.div`
  width: 100%;
  height: 32px;
`;

const IntroCard = (props) => {
  const { title, subtitle, text, index, viewport, deliverables } = props;

  const ImageIndex = index === 0 ? Number1 : index === 1 ? Number2 : Number3;

  return (
    <CardContainer viewport={viewport}>
      <TopContainer>
        <ImageNumber src={ImageIndex}/>
      </TopContainer>
      <TopContentContainer>
        <Text
          color={colors.lightNavyBlue}
          fontWeight='bold'
          primitive="h3"
          size="large">
          {title}
        </Text>
        <Text
          color={colors.lightNavyBlue}
          fontWeight='bold'
          primitive="h3"
          size="medium">
          {subtitle}
        </Text>

        <Spacer/>

        <Body size="medium" color={colors.lightNavyBlue}>
          {text}
        </Body>

      </TopContentContainer>
      <BottomContentContainer>
        <Text
          color={colors.olive}
          fontWeight='bold'
          primitive="h3"
          size="small">
          PROCESS & DELIVERABLES
        </Text>
        <DeliverablesList>
          {deliverables?.map((item, index) => {
          if (!item) return null;
          return (
            <Text key={index} color={colors.olive} primitive="li" family="crimson" size="small">{item}</Text>
          )
          })}
        </DeliverablesList>
      </BottomContentContainer>
    </CardContainer>
  );
};

IntroCard.propTypes = propTypes;
IntroCard.defaultProps = defaultProps;

export default withManager(IntroCard);