import { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { colors, screenSize } from "theme";
import HomeHeroImage1 from "../../images/impact-hero-image-1.jpg";
import HomeHeroImage2 from "../../images/impact-hero-image-2.jpg";
import HomeHeroTextWhite1 from "../../images/svgs/impact-hero-white-1.svg";
import HomeHeroTextWhite2 from "../../images/svgs/impact-hero-white-2.svg";
import { withManager } from "utilities";
import Swiper from "react-id-swiper";

import TextTransition, { presets } from "react-text-transition";

const propTypes = {
  imageSource: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  category: PropTypes.string,
  hideButton: PropTypes.bool,
};

const defaultProps = {};

const HeroContainer = styled.div`
  height: 80vh;
  width: 100%;
  background: url(${HomeHeroImage1}) no-repeat center;
  background-size: cover;
  position: relative;

  & .swiper-wrapper {
  }
  & .swiper-container {
    height: 100%;
    overflow: hidden;
    background-size: cover;
    position: relative;
  }

  & .swiper-container .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  & .swiper-container .flexEnd {
    justify-content: flex-end;
    @media only screen and (max-width: ${screenSize.tablet}) {
      justify-content: flex-end;
    }
  }

  & .swiper-slide-active {
    opacity: 1;
    transition: all 600ms ease;
  }

  & .swiper-slide-prev {
    transition: all 600ms ease;
    opacity: 0;
  }

  & .swiper-slide-next {
    transition: all 600ms ease;
    opacity: 0;
  }
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  padding-left: ${({ flexEnd }) => (flexEnd ? "0" : "8%")};
  padding-right: ${({ flexEnd }) => (flexEnd ? "8%" : "0")};
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${screenSize.tablet}) {
    padding-left: 4%;
    padding-right: 0%;
  }
`;

const HeaderText = styled.h2`
  font-family: "forma-djr-text", Helvetica, sans-serif;
  font-size: 65px;
  color: ${colors.beige};
  margin: 0;
  line-height: 68px;

  @media only screen and (max-width: ${screenSize.tablet}) {
    font-size: 45px;
    line-height: 48px;
  }

  @media only screen and (max-width: ${screenSize.mobile}) {
  }
`;

const Background1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: url(${HomeHeroImage1}) no-repeat center;
  background-size: cover;

  @media only screen and (max-width: ${screenSize.tablet}) {
      justify-content: flex-start;
    }
`;

const Background2 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: url(${HomeHeroImage2}) no-repeat center;
  background-size: cover;
`;

const HomeHero = (props) => {
  const [index, setIndex] = useState(0);
  const [scrolled, setScrolled] = useState();

  const TEXTS = [
    "Marketing ",
    "Brand ",
    "Product ",
    "Design ",
    "Insights ",
    "User Experience ",
  ];

  const _getSwiper = (instance) => {

    instance.on("reachEnd", () => {
      setScrolled(true)
      instance.mousewheel.enabled = false;
      instance.allowSlidePrev = false;
      setTimeout(() => {
        document.body.style.height = "100%";
        document.body.style.overflow = "visible";
      }, 500);
    });
  };

  const params = {
    getSwiper: _getSwiper,
    direction: "vertical",
    effect: 'coverflow',
    slidesPerView: 1,
    mousewheel: {
      forceToAxis: true,
      sensitivity: 100,
      releaseOnEdges: true,
    },
    touchReleaseOnEdges: {
      threshold: 100,
    },
  };

  useEffect(() => {
    if (!scrolled) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    }
    if (scrolled) return;
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      1500 // every 1.5 seconds
    );
    return () => clearTimeout(intervalId);
  }, [scrolled]);

  return (
    <HeroContainer id="heroAnimation" scrolled={scrolled}>
      <Swiper {...params}>
        <div  style={{ position: "relative" }}>
          <Background1>
          <ContentContainer>
            <HeaderText
              style={{ marginBottom: "8px" }}
              color={colors.beige}
              fontWeight="bold"
              primitive="h3"
              size="large"
            >
              <TextTransition
                inline
                direction="up"
                className={"dope"}
                text={TEXTS[index % TEXTS.length]}
                springConfig={presets.gentle}
              />
              {/* {' ' + ' '} leaders know exceptional insights */}
            </HeaderText>
            <HeaderText
              color={colors.beige}
              fontWeight="bold"
              primitive="h3"
              size="large"
            >
              leaders know
            </HeaderText>
            <HeaderText
              style={{ marginBottom: "16px" }}
              color={colors.beige}
              fontWeight="bold"
              primitive="h3"
              size="large"
            >
              exceptional insights
            </HeaderText>
            <img width={"90%"} src={HomeHeroTextWhite1} />
          </ContentContainer>
          </Background1>
          {/* <Overlay /> */}
        </div>

        <div
          className="flexEnd"
          
          style={{ position: "relative" }}
        >
          <Background2 flexEnd>
          <ContentContainer flexEnd>
            <HeaderText
              style={{ marginBottom: "8px" }}
              color={colors.beige}
              fontWeight="bold"
              primitive="h3"
              size="large"
            >
              We help global businesses
            </HeaderText>
            <img width={"80%"} src={HomeHeroTextWhite2} />
          </ContentContainer>
          </Background2>
          {/* <Overlay2 /> */}
        </div>
      </Swiper>
    </HeroContainer>
  );
};

HomeHero.propTypes = propTypes;
HomeHero.defaultProps = defaultProps;

export default withManager(HomeHero);
