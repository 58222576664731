import styled from "styled-components";
import PropTypes from "prop-types";
import { Text, Body } from "components";
import { colors, screenSize } from "theme";
import JoeyImage from '../../images/impact-photo-Joey.png';
import { withManager } from 'utilities';

const propTypes = {
  imageSource: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  category: PropTypes.string,
  hideButton: PropTypes.bool,
};

const defaultProps = {
  image: JoeyImage
};

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width:550px;
  box-sizing: border-box;
  margin-right: ${({lastItem}) => lastItem ? '300px' : null};
`;

const BackgroundElement = styled.div`
  position: absolute;
  top: -20px;
  left: -20px;
  width: 441px;
  height: 441px;
  background-color: ${colors.beige};
  z-index: -1;
  /* box-shadow: inset -4px 4px 0px #938861, inset 4px -4px 0px rgba(255, 255, 255, 0.3); */

  @media only screen and (max-width: ${screenSize.tablet}) {
		width: 291px;
		height: 291px;
	}

  @media only screen and (max-width: ${screenSize.mobile}) {
		width: 221px;
		height: 221px;
    top: -10px;
    left: -10px;
	}
`;

const ContentContainer = styled.div`
  padding-top: 36px;
  margin-left: -20px;

  @media only screen and (max-width: ${screenSize.mobile}) {
    padding-top: 16px;
    margin-left: -10px;
    }
`;

const TeamImage = styled.img`
  box-shadow: 0 6px 26px rgba(0, 0, 0, 0.36);
  position: relative;

  @media only screen and (max-width: ${screenSize.tablet}) {
		width: 250px;
	}

  @media only screen and (max-width: ${screenSize.mobile}) {
		width: 200px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const TeamCard = (props) => {
  const { name, image, title, location, lastItem, viewport } = props;

  // const { name, title, location, text } = data;

  return (
    <CardContainer viewport={viewport} lastItem={lastItem}>
      <ImageWrapper>
        <TeamImage src={image}/>
        <BackgroundElement/>
      </ImageWrapper>
      
      <ContentContainer>
        <Text
          texttransform={'uppercase'}
          color={colors.white}
          fontWeight='bold'
          primitive="h6"
          size="small">
          {name}
        </Text>
        <Text
          texttransform={'uppercase'}
          color={colors.beige}
          fontWeight='bold'
          primitive="h6"
          size="small">
        {title}
        </Text>
        <Body
          color={colors.beige}
          fontWeight='bold'
          size="medium">
          {location}
        </Body>
      </ContentContainer>
    </CardContainer>
  );
};

TeamCard.propTypes = propTypes;
TeamCard.defaultProps = defaultProps;

export default withManager(TeamCard);