import { useState } from "react";
import styled from "styled-components";
// import PropTypes from "prop-types"
import { Burger } from "components";
import logo from "../../images/impact-strategies-logo.svg";
import logoLight from "../../images/impact-strategies-logo-light.svg";
// import logoLight from "../../images/impact-strategies-logo-light.png";
import { colors, screenSize } from "theme";

const Wrapper = styled.div`
  width: 100%;
`;
const NavWrapper = styled.nav`
  height: 142px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: all 300ms linear;
  transition: height 450ms ease-in-out;
  background-color: ${colors.lightOrange};
  margin: 26px 20px;
  box-sizing: border-box;
  overflow: hidden;
  @media only screen and (max-width: ${screenSize.nav}) {
    margin: 0 auto;
    height: 100px;

    ${({open}) => open &&`
      transition: all 300ms linear;
      background-color: ${colors.orange};
      height: 90vh;
  `}
  }
  
`;

const NavItemWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  position: relative;
  transition: top 300ms ease;

  @media only screen and (max-width: ${screenSize.nav}) {
    flex-direction: column;
    text-align: left;
    transition: top 300ms ease;
    position: absolute;
    top: ${({ open }) => open ? '10px' : "-850px"};
    right: 60px;
  }
`;

const BurgerWrapper = styled.div`
  position: relative;
`;

const LogoWrapper = styled.a`
  padding-left: 40px;
  padding-bottom: 26px;
  align-self: flex-end;

  @media only screen and (max-width: ${screenSize.nav}) {
    padding-top: 26px;
    padding-left: 20px;
  }
`;

const NavItem = styled.li`
  list-style-type: none;
  width: 150px;
  margin-top: 15px;
  @media only screen and (max-width: ${screenSize.nav}) {
    width: 200px;
  
  }

    `;

const Link = styled.a`
  font-family: "forma-djr-text", Helvetica, sans-serif;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  color: ${({ open }) => (open ? colors.beige : colors.orange)};
  transition: background-color 300ms ease;
  transition: color 300ms ease;
  cursor:'pointer';

  ::before {
    width: 100%;
    height: 10px;
    transition: background-color 300ms ease;
    background-color: ${colors.orange};
    content: '';
    position: absolute;
    top: -15px;
  }

  &:hover {
    color: ${colors.navyBlue};
    transition: background 300ms ease;
    transition: color 300ms ease;
  }

  &:hover::before {
    transition: background 300ms ease;
    background-color: ${colors.navyBlue};
  }


  @media only screen and (max-width: ${screenSize.nav}) {
    font-size: 28px;
    width: auto;
    margin: 0 12px;

    ::before {
      transition: background 300ms ease;
      background-color: ${colors.beige};
  }

    &:hover::before {
      transition: background 300ms ease;
      background-color: ${colors.navyBlue};
    }
  }
`;

const LogoImage = styled.img`
  width: 325px;

  @media only screen and (max-width: 580px) {
    width: 180px;
  }
`;

const NavBar = (props) => {
  const [open, setOpen] = useState(false);

  const _handleClick = () => {
    document.body.style.height = "100%";
    document.body.style.overflow = "visible";
    if (open) setOpen(false);
  }

  return (
    <Wrapper>
      <NavWrapper open={open}>
        <LogoWrapper href="/" title="Logo">
          <LogoImage
            src={open ? logoLight : logo}
            alt="tex-air gas logo of atoms and ions representing industrial gas"
            title="Tex-Air Gas logo of atoms and ions representing industrial gas"
          />
        </LogoWrapper>
        <BurgerWrapper>
          <Burger open={open} setOpen={setOpen} />
        </BurgerWrapper>
        <NavItemWrapper open={open}>
          <NavItem open={open}>
            <Link open={open} onClick={() => _handleClick()} href="#what">What we do</Link>
          </NavItem>
          <NavItem open={open}>
            <Link open={open} onClick={() => _handleClick()} href="#how">How we do it</Link>
          </NavItem>
          <NavItem open={open}>
            <Link open={open} onClick={() => _handleClick()} href="#who">Who we are</Link>
          </NavItem>
          <NavItem open={open}>
            <Link open={open} onClick={() => _handleClick()} href="#contact">Contact</Link>
          </NavItem>
        </NavItemWrapper>
      </NavWrapper>
    </Wrapper>
  );
};

export default NavBar;
