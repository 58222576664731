import styled from 'styled-components';
import { colors, screenSize } from 'theme';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import { Text } from '../Text';
import { Body } from '../Body';

const propTypes = {
  titlePrimitive: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  titleSize: PropTypes.oneOf(['xxlarge', 'xlarge', 'large', 'medium', 'small', 'xsmall', 'xxsmall', 'xxxsmall', 'body', 'bodySmall']),
  bodySize: PropTypes.oneOf(['xsmall', 'small', 'medium']),
  children: PropTypes.string,
  align: PropTypes.string,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  maxWidth: PropTypes.string
},
  defaultProps = {
    titlePrimitive: 'h2',
    titleSize: 'xlarge',
    bodySize: 'medium',
    titleColor: colors.blue,
    align: 'center',
    titleColor: colors.navyBlue,
    subtitleColor: colors.olive,
    textColor: colors.navyBlue,
    maxWidth: '1000px'
  };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ align }) => align === 'center' ? 'center' : null};
  align-items: ${({ align }) => align === 'center' ? 'center' : null};
  text-align: ${({ align }) => align === 'center' ? "center" : "left"};
  max-width: ${({ maxWidth }) => maxWidth ? maxWidth : null};
  margin: ${({ align }) => align === 'center' ? '0 auto' : 0};
  
  @media only screen and (max-width: ${screenSize.tablet}) {
    padding: 0 16px;
  }
`;

const Divider = styled.div`
  /* width: 196px; */
  height: 5px;
  background-color: ${({subtitleColor}) => subtitleColor};
  margin-bottom : 40px;
  position: relative;
`;

const HeaderWrapper = styled.div`
  margin-bottom : 10px;
`;

const TextBlock = (props) => {

  const {
    title,
    subtitle,
    text,
    align,
    maxWidth,
    titleColor,
    subtitleColor,
    textColor,
    titlePrimitive,
    depth,
  } = props;

  return (
    <Wrapper
      align={align}
      maxWidth={maxWidth}>
      <HeaderWrapper {...props}>
        <ScrollAnimation animateOnce animateIn="fadeInUp" offset={200}>
          <Text
            type='subtitle'
            family='crimson'
            fontWeight='800'
            color={subtitleColor}
            size='large'
            align={align}>
            {subtitle}
          </Text>
        </ScrollAnimation>
        <ScrollAnimation animateOnce animateIn="fadeInUp" offset={300} delay={80}>
          <Text
            fontWeight='700'
            className={depth ? 'depth' : null}
            color={titleColor}
            size='large'
            primitive={titlePrimitive}
            align={align}>
            {title}
          </Text>
        </ScrollAnimation>
      </HeaderWrapper>
      <ScrollAnimation duration={.75} animateOnce animateIn="dividerWipe" offset={200} delay={120}>
        <Divider subtitleColor={subtitleColor}/>
      </ScrollAnimation>
      {text &&
        <ScrollAnimation animateOnce animateIn="fadeIn" offset={200} delay={150}>
        <Body
          primitive="p"
          size='large'
          align={align}
          color={textColor}>
          {text}
        </Body>
        </ScrollAnimation>
      }
    </Wrapper>
  )
};

TextBlock.propTypes = propTypes;
TextBlock.defaultProps = defaultProps;

export default TextBlock;