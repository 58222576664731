const homeData = {
  hero: {
    subtitle: 'Our Craft:',
    title: 'Translating insights into action.',
    text: 'We leverage our global network of industry experts to craft insights, ideas, and strategies that ignite long-term business growth.',
  },
  what: {
    subtitle: 'Our Craft:',
    title: 'Translating insights into action.',
    text: 'We leverage our global network of industry experts to craft insights, ideas, and strategies that ignite long-term business growth.',
    cards: [
      {
        title: "Define",
        subtitle: "the challenge.",
        text: "We look at the big-picture conditions in front of you, carve out a reliable path forward, and pinpoint the right stakeholders to engage along the way.",
        deliverables: [
          "Stakeholder interviews",
          "Knowledge audit ",
          "Landscape + category analysis ",
          "Competitive research + analysis"

        ]
      },
      {
        title: "Design",
        subtitle: "the approach.",
        text: "As an extension of your team, we’ll custom-design an approach that helps you achieve specific, desired outcomes for your project.",
        deliverables: [
          "Online research",
          "In-home research",
          "Group interviews",
          "Cultural + category immersion"
        ]
      },
      {
        title: "Make",
        subtitle: "your mark.",
        text: "We create engaging deliverables that inspire audiences by telling captivating stories that live on long after the project ends.",
        deliverables: [
          "Consumer documentary films",
          "Infographics & Journey maps ",
          "Insight storytelling",
          "Workshops",
          "Deployment sessions"
        ]
      }
    ]
  },
  how: {
    subtitle: 'Our Perspective:',
    title: 'Context is everything.',
    text: 'The biggest growth opportunities come from zooming out and looking at the bigger picture. During each project, we think beyond the brief and consider the broader conditions affecting your business and customers.',
  },
  who: {
    subtitle: 'Our Team:',
    title: 'Experience wins.',
  }
}
export default homeData;
