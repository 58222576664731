export const titleConfig = {
    xlarge: {
        desktop: {
            fontSize: '160px',
            lineHeight: '150px',
        },
        tablet: {
            fontSize: '90px',
            lineHeight: '91px',
        },
        mobile: {
            fontSize: '65px',
            lineHeight: '67px',
        }
    },
    large: {
        desktop: {
            fontSize: '60px',
            lineHeight: '60px',
        },
        tablet: {
            fontSize: '46px',
            lineHeight: '46px',
        },
        mobile: {
            fontSize: '36px',
            lineHeight: '36px',
        }
    },
    medium: {
        desktop: {
            fontSize: '40px',
            lineHeight: '42px',
        },
        tablet: {
            fontSize: '36px',
            lineHeight: '28px',
        },
        mobile: {
            fontSize: '28px',
            lineHeight: '30px',
        }
    },
    small: {
        desktop: {
            fontSize: '21px',
            lineHeight: '24px',
        },
        tablet: {
            fontSize: '20px',
            lineHeight: '22px',
        },
        mobile: {
            fontSize: '18px',
            lineHeight: '24px',
        }
    }
}

export const subtitleConfig = {
    large: {
        desktop: {
            fontSize: '50px',
            lineHeight: '60px',
        },
        tablet: {
            fontSize: '42px',
            lineHeight: '49px',
        },
        mobile: {
            fontSize: '24px',
            lineHeight: '37px',
        }
    },
    medium: {
        desktop: {
            fontSize: '26px',
            lineHeight: '32px',
        },
        tablet: {
            fontSize: '24px',
            lineHeight: '28px',
        },
        mobile: {
            fontSize: '21px',
            lineHeight: '26px',
        }
    },
    small: {
        desktop: {
            fontSize: '21px',
            lineHeight: '24px',
        },
        tablet: {
            fontSize: '20px',
            lineHeight: '22px',
        },
        mobile: {
            fontSize: '18px',
            lineHeight: '24px',
        }
    }
}

export const bodyConfig = {
    large: {
        desktop: {
            fontSize: '28px',
            lineHeight: '34px',
        },
        tablet: {
            fontSize: '24px',
            lineHeight: '30px',
        },
        mobile: {
            fontSize: '16px',
            lineHeight: '18px',
        }
    },
    medium: {
        desktop: {
            fontSize: '20px',
            lineHeight: '26px',
        },
        tablet: {
            fontSize: '18px',
            lineHeight: '28px',
        },
        mobile: {
            fontSize: '17px',
            lineHeight: '24px',
        }
    },
    small: {
        desktop: {
            fontSize: '16px',
            lineHeight: '21px',
        },
        tablet: {
            fontSize: '16px',
            lineHeight: '21px',
        },
        mobile: {
            fontSize: '16px',
            lineHeight: '21px',
        }
    }
  }