import styled from "styled-components";
import { Text, TextBlock, Body, Seo } from "../components";
import { NavBar,  HomeHero, IntroCard, Footer, Slider } from "../modules";
import homeData from "../data/homeData";
import { colors, screenSize } from "theme";
import { withManager } from "utilities";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import ScrollAnimation from "react-animate-on-scroll";

import DotPatternCream from "../images/svgs/dot-pattern-cream.svg";
import DotPatternNavyBlue from "../images/svgs/dot-pattern-navyBlue.svg";
import DotPatternOliveGroup from "../images/svgs/dot-pattern-olive-group.svg";
import DotPatternOliveGroupMobile from "../images/svgs/dot-pattern-olive-group-mobile.svg";
import DotPatternOlive from "../images/svgs/dot-pattern-olive.svg";
import HowImageFile from "../images/impact-how-image.jpg";
import PartnersImageFile from "../images/impact-partnerships.jpg";
import HowGraphicFile from "../images/svgs/art-context-graph-OL2.svg";
import HowGraphicFileMobile from "../images/svgs/art-context-graph-OL-mobile2.svg";
import GlobeImageFile from "../images/impact-global-map.jpg";

import "../components/css/layout.css";

const HomePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 100%;
`;

// INTRO SECTION
const IntroWrapper = styled.div`
  padding-top: 182px;
  position: relative;

  & .introCardsParallax {
    background-color: ${colors.lightOrange};
    margin-bottom: 0;
  }
  ::before {
    content: " ";
    position: absolute;
    top: -75px;
    z-index: -1;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 175px;
    background: url(${DotPatternCream}) center;
  }
  ::after {
    content: " ";
    position: absolute;
    top: 0;
    z-index: -1;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 20px;
    background: ${colors.beige};
  }
`;

const IntroCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.lightOrange};
  margin-top: 220px;
  padding: 0 32px;
  padding-bottom: 182px;
  flex-wrap: wrap;
  position: relative;

  @media only screen and (max-width: ${screenSize.tablet}) {
    padding-bottom: 382px;
  }

  @media only screen and (max-width: ${screenSize.tablet}) {
    padding-bottom: 282px;
  }
`;

const InnerCardWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  .introCard {
    width: 32%;
  }

  @media only screen and (max-width: ${screenSize.tablet}) {
    flex-direction: column;
    .introCard {
      width: 100%;
    }
  }
`;

// HOW SECTION
const HowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 182px;
  background: ${colors.olive};
  position: relative;

  ::before {
    content: " ";
    position: absolute;
    top: 0;
    /* z-index: 2; */
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 0;
    height: 525px;
    background: url(${DotPatternOliveGroup}) center;
  }

  ::after {
    content: " ";
    position: absolute;
    bottom: 0;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 20px;
    background: ${colors.darkOlive};
  }

  @media only screen and (max-width: ${screenSize.tablet}) {
    ::before {
      width: 100%;
      background: url(${DotPatternOliveGroupMobile}) center;
      height: 240px;
    }
  }
`;

const HowImage = styled.div`
  width: 85%;
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
  margin: 0 auto;
  margin-top: -20px;
  margin-bottom: 62px;
  height: 520px;
  background: url(${HowImageFile}) no-repeat center;
  background-size: cover;
  border-top: 20px solid ${colors.navyBlue};
  @media only screen and (max-width: ${screenSize.tablet}) {
    width: 95%;
    height: 250px;
  }
`;

const HowGraphicWrapper = styled.div`
  overflow: hidden;
  width: 97vw;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${screenSize.tablet}) {
    width: 100vw;
  }
`;

const HowGraphic = styled.img`
  width: 105%;
  margin: 0 auto;
  margin-top: 36px;
  max-width: 1080px;

  @media only screen and (max-width: ${screenSize.tablet}) {
    width: 140%;
    max-width: auto;
    margin-left: -25%;
  }

  @media only screen and (max-width: ${screenSize.mobile}) {
    width: 145%;
  }
`;

// WHO SECTION
const WhoWrapper = styled.div`
  background: ${colors.navyBlue};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .partnersParallax {
    z-index: 4;
    width: 100%;
  }
`;

const WhoDotPattern = styled.div`
  position: relative;
  top: 0px;
  z-index: 0;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 125px;
  background: url(${DotPatternOlive}) center;
`;

const WhoIntro = styled.div`
  background-color: ${colors.altBlue};
  width: 85%;
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
`;

const WhoIntroContent = styled.div`
  width: 65%;
  padding: 32px 9%;

  p {
    max-width: 549px;
  }

  @media only screen and (max-width: ${screenSize.tablet}) {
    width: 100%;
    padding: 32px;
  }
`;

const WhoIntroPattern = styled.div`
  background: url(${DotPatternNavyBlue});
  height: 175px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 32px;
  position: relative;
`;

const WhoPartners = styled.div`
  background-color: ${colors.altBlue};
  width: 85%;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 32px 7%;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: relative;
  margin: 0 auto;

  @media only screen and (max-width: ${screenSize.tablet}) {
    flex-direction: column-reverse;
    padding: 32px 6px;
    text-align: center;
  }
`;

const PartnersImage = styled.div`
  width: 65%;
  height: 350px;
  background: url(${PartnersImageFile});
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: ${screenSize.tablet}) {
    width: 80%;
    height: 250px;
  }
`;
const PartnersContent = styled.div`
  width: 80%;
  padding: 16px 0 0 32px;
  @media only screen and (max-width: ${screenSize.tablet}) {
    padding: 0 0 16px 0;
  }
`;

const WhoGlobeWrapper = styled.div`
  width: 85%;
  height: 800px;
  position: relative;
  bottom: 0;
  margin-top: -40px;
  background: url(${GlobeImageFile}) no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 32px 6%;
  @media only screen and (max-width: ${screenSize.tablet}) {
    padding: 0;
    width: 100%;
    height: 650px;
  }
`;

const WhoGlobeContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  width: 100%;
  max-width: 960px;

  p {
    max-width: 549px;
  }

  @media only screen and (max-width: ${screenSize.tablet}) {
    padding: 32px;
  }
`;

const GlobeContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1600px;
`;

// markup
const IndexPage = (props) => {
  const { what, how, who } = homeData;
  const { viewport } = props;

  return (
    <ParallaxProvider>
      <HomePageWrapper>
        <NavBar />
        <Seo />
        <Wrapper>
          <Main>
            <HomeHero />

            {/* Intro / What We Do */}
            <IntroWrapper id="what">
              <TextBlock
                title={what.title}
                subtitle={what.subtitle}
                text={what.text}
              />
              <IntroCardWrapper>
                <Parallax
                  className="introCardsParallax"
                  y={[-10, 10]}
                  tagOuter="figure"
                >
                  <InnerCardWrapper viewport={viewport}>
                    {what.cards?.map((item, index) => {
                      if (!item) return null;
                      return (
                        <ScrollAnimation
                          key={index}
                          animateOnce
                          animateIn="fadeInUpSmall introCard"
                          delay={index * 100}
                        >
                          <IntroCard
                            key={index}
                            index={index}
                            title={item.title}
                            subtitle={item.subtitle}
                            text={item.text}
                            deliverables={item.deliverables}
                          />
                        </ScrollAnimation>
                      );
                    })}
                  </InnerCardWrapper>
                </Parallax>
              </IntroCardWrapper>
            </IntroWrapper>

            {/* Perspective / How we do it */}
            <HowWrapper id="how">
              <Parallax y={[-30, 10]} tagOuter="figure">
                <div style={{ width: "97vw", position: "relative", zIndex: 6 }}>
                  <HowImage />
                </div>
              </Parallax>
              <TextBlock
                depth
                title={how.title}
                titleColor={colors.white}
                subtitle={how.subtitle}
                subtitleColor={colors.beige}
                text={how.text}
                textColor={colors.beige}
              />

              <HowGraphicWrapper>
                <ScrollAnimation
                  animateOnce
                  animateIn="fadeInUpSmall"
                  offset={200}
                >
                  <HowGraphic
                    viewport={viewport}
                    src={
                      viewport !== "desktop"
                        ? HowGraphicFileMobile
                        : HowGraphicFile
                    }
                  />
                </ScrollAnimation>
              </HowGraphicWrapper>
            </HowWrapper>

            {/* Our Team / Who we are */}
            <WhoWrapper id="who">
              <Parallax y={[-175, 0]} tagOuter="figure">
                <div style={{ width: "97vw", position: "relative", zIndex: 6 }}>
                  <WhoDotPattern />
                </div>
              </Parallax>
              <TextBlock
                depth
                title={who.title}
                titleColor={colors.white}
                subtitle={who.subtitle}
                subtitleColor={colors.beige}
              />

              <WhoIntro>
                <WhoIntroContent viewport={viewport}>
                  <Text
                    size="medium"
                    fontWeight="bold"
                    align={viewport === "desktop" ? "left" : "center"}
                    color={colors.white}
                  >
                    Teeming with
                  </Text>
                  <Text
                    size="medium"
                    fontWeight="bold"
                    align={viewport === "desktop" ? "left" : "center"}
                    color={colors.white}
                  >
                    tenured talent.
                  </Text>
                  <Body
                    color={colors.beige}
                    align={viewport === "desktop" ? "left" : "center"}
                  >
                    When you work with IMPACT, you’re teaming up with seasoned
                    and disciplined senior-level talent to lead your
                    project—every time.
                  </Body>
                </WhoIntroContent>
                <WhoIntroPattern />
              </WhoIntro>
              <Slider />

              <Parallax
                y={[-10, 20]}
                className="partnersParallax"
                tagOuter="figure"
              >
                <WhoPartners viewport={viewport}>
                  <PartnersImage>
                    <img src={""} />
                  </PartnersImage>
                  <PartnersContent>
                    <Text
                      size="medium"
                      fontWeight="bold"
                      color={colors.white}
                      align={viewport === "desktop" ? "left" : "center"}
                    >
                      Partnerships
                    </Text>
                    <Text
                      size="medium"
                      fontWeight="bold"
                      color={colors.white}
                      align={viewport === "desktop" ? "left" : "center"}
                    >
                      with purpose.
                    </Text>
                    <Body
                      color={colors.beige}
                      align={viewport === "desktop" ? "left" : "center"}
                    >
                      We believe that deeper, long-term client relationships
                      lead to more impactful work, and strive to play an
                      embedded role, working alongside your team from start to
                      finish.
                    </Body>
                  </PartnersContent>
                </WhoPartners>
              </Parallax>
              <WhoGlobeWrapper>
                <Parallax y={[-70, 70]} tagOuter="figure">
                  <WhoGlobeContent viewport={viewport}>
                    <ScrollAnimation
                      animateOnce
                      animateIn="fadeInUp"
                      offset={200}
                    >
                      <GlobeContentWrapper>
                        <Text
                          size="medium"
                          fontWeight="bold"
                          color={colors.white}
                          align={viewport === "desktop" ? "left" : "center"}
                        >
                          Global reach.
                        </Text>
                        <Text
                          size="medium"
                          fontWeight="bold"
                          color={colors.white}
                          align={viewport === "desktop" ? "left" : "center"}
                        >
                          Local expertise.
                        </Text>
                        <Body
                          color={colors.beige}
                          align={viewport === "desktop" ? "left" : "center"}
                        >
                          Our vast partner network spans the globe, allowing us to
                          field projects in nearly any country and category.
                        </Body>
                      </GlobeContentWrapper>
                    </ScrollAnimation>
                  </WhoGlobeContent>
                </Parallax>
              </WhoGlobeWrapper>
            </WhoWrapper>
          </Main>
        </Wrapper>

        <Footer />
      </HomePageWrapper>
    </ParallaxProvider>
  );
};

export default withManager(IndexPage);
