import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { titleConfig, subtitleConfig } from '../typeConfig';
import { colors } from 'theme';
import { withManager } from 'utilities';

const propTypes = {
  primitive: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'article', 'span', 'li']),
  size: PropTypes.oneOf(['xxlarge', 'xlarge', 'large', 'medium', 'small', 'xsmall', 'xxsmall', 'xxxsmall', 'body', 'bodySmall']),
  children: PropTypes.any,
  fontWeight: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  type: PropTypes.oneOf(['title', 'subtitle'])
},
  defaultProps = {
    primitive: 'h3',
    size: 'large',
    align: 'left',
    color: colors.black,
    fontWeight: 'normal',
    type: 'title'
  };

const Wrapper = styled(props => {
  return React.createElement(props.primitive, props, props.children);
})`
  line-height: ${({ fontStyle }) => fontStyle.lineHeight};
  font-family: ${({ family }) => family === 'crimson' ? '"Crimson Pro", Helvetica, sans-serif' : '"forma-djr-text", Helvetica, sans-serif'};
  font-size: ${({ fontStyle }) => fontStyle.fontSize};
  letter-spacing: ${({ fontStyle }) => fontStyle.letterSpacing};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ align }) => align};
  color: ${({ color }) => color};
  margin: 0;
  text-decoration: none;
  text-transform: ${({ texttransform }) => texttransform ?? null}
`;

const Text = (props) => {
  const {
    children,
    size,
    viewport,
    fontWeight,
    type,
    className,
    texttransform,
    ...rest
  } = props;

  const fontStyle = type === 'subtitle' ? subtitleConfig[size][viewport] : titleConfig[size][viewport];

  return (
    <Wrapper
      {...rest}
      className={className}
      texttransform={texttransform}
      id={'text-'+ size}
      size={size}
      fontWeight={fontWeight}
      fontStyle={{ ...fontStyle }}>
      {children}
    </Wrapper>
  )
};

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;
export default withManager(Text);